import React, { useState, useRef, useEffect } from 'react';
import styles from './Chat.module.css';

interface Message {
    role: 'user' | 'assistant';
    content: string;
}

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5008';

const Chat: React.FC = () => {
    // State management
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputMessage, setInputMessage] = useState('');
    const [isThinking, setIsThinking] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [voiceEnabled, setVoiceEnabled] = useState(false);
    const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null);

    // Refs
    const chatContainerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    // Effect to auto-focus input on mount
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    // Effect to maintain focus after sending message
    useEffect(() => {
        if (!isThinking && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isThinking]);

    // Effect to scroll to bottom when messages change
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    // Effect for initial server connection test and cleanup
    useEffect(() => {
        // Test server connection
        fetch(`${API_URL}/api/test`)
            .then(res => res.json())
            .then(data => console.log('Server connection test:', data))
            .catch(err => console.error('Server connection test failed:', err));

        // Cleanup function for audio
        return () => {
            if (currentAudio) {
                currentAudio.pause();
                currentAudio.currentTime = 0;
            }
        };
    }, []);

    // Speech handling
    const speakMessage = async (text: string) => {
        try {
            if (currentAudio) {
                currentAudio.pause();
                currentAudio.currentTime = 0;
            }

            const response = await fetch(`${API_URL}/api/text-to-speech`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text })
            });

            if (!response.ok) {
                throw new Error('Failed to generate speech');
            }

            const audioBlob = await response.blob();
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            
            setCurrentAudio(audio);
            
            audio.onended = () => {
                setCurrentAudio(null);
                URL.revokeObjectURL(audioUrl);
            };

            await audio.play();
        } catch (error) {
            console.error('Error in text-to-speech:', error);
        }
    };

    const stopSpeaking = () => {
        if (currentAudio) {
            currentAudio.pause();
            currentAudio.currentTime = 0;
            setCurrentAudio(null);
        }
    };

    const toggleVoice = () => {
        setVoiceEnabled(!voiceEnabled);
        if (currentAudio) {
            stopSpeaking();
        }
    };

    // Message handling
    const handleSendMessage = async () => {
        if (!inputMessage.trim()) return;

        const userMessage = inputMessage;
        setInputMessage('');
        setIsThinking(true);
        setError(null);

        // Add user message to chat
        setMessages(prev => [...prev, { role: 'user', content: userMessage }]);

        try {
            const response = await fetch(`${API_URL}/api/send_message`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message: userMessage })
            });

            if (!response.ok) {
                throw new Error(`Server responded with ${response.status}`);
            }

            const data = await response.json();

            if (data.message) {
                setMessages(prev => [...prev, { 
                    role: 'assistant', 
                    content: data.message 
                }]);
                
                if (voiceEnabled) {
                    await speakMessage(data.message);
                }
            }
        } catch (error) {
            console.error('Error sending message:', error);
            setError('Failed to send message. Please try again.');
            setMessages(prev => [...prev, { 
                role: 'assistant', 
                content: 'Sorry, I encountered an error. Please try again.' 
            }]);
        } finally {
            setIsThinking(false);
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && !e.shiftKey && inputMessage.trim()) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    // Render component
    return (
      <div className={styles.mainpage}>
       <a href="/"  rel="noopener noreferrer">
       <img className={styles.logo} src="logo.png" alt="" />
        </a>
        
        <div className={styles.chatContainer}>
            <div className={styles.controls}>
                <h2>ArgoD2</h2>
                <button 
                    onClick={toggleVoice} 
                    className={`${styles.controlButton} ${voiceEnabled ? styles.enabled : ''}`}
                >
                    {voiceEnabled ? 'Voice: ON' : 'Voice: OFF'}
                </button>
                {voiceEnabled && currentAudio && (
                    <button onClick={stopSpeaking} className={styles.controlButton}>
                        Stop Speaking
                    </button>
                )}
            </div>

            {/* Messages Container */}
            <div className={styles.messagesContainer} ref={chatContainerRef}>
                {messages.map((msg, index) => (
                    <div key={index} className={`${styles.message} ${styles[msg.role]}`}>
                        <strong>{msg.role === 'user' ? 'You:' : 'ArgoD2:'}</strong>
                        <div>{msg.content}</div>
                    </div>
                ))}
                {isThinking && (
                    <div className={`${styles.message} ${styles.thinking}`}>
                        <strong>ArgoD2 is thinking...</strong>
                    </div>
                )}
                {error && (
                    <div className={styles.errorMessage}>
                        {error}
                    </div>
                )}
            </div>

            {/* Input Container */}
            <div className={styles.inputContainer}>
                <input
                    ref={inputRef}
                    type="text"
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Type your message..."
                    className={styles.messageInput}
                    disabled={isThinking}
                    autoFocus
                />
                <button 
                    onClick={handleSendMessage} 
                    className={styles.sendButton}
                    disabled={isThinking || !inputMessage.trim()}
                >
                    Send
                </button>
            </div>
        </div>
        </div>
    );
};

export default Chat;